@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  border-top-color: #3498db; /* Customize the color here */
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
